export const lensesInfo = (hubblePricing) => ({
  promo: {
    sub_title: "START NOW FOR LESS",
    title: "HYDRO",
    cta_text: `You can now start your Hydro by Hubble subscription for just <b>$${hubblePricing.hydro.firstPrice}/eye</b> for your first order.`,
    btn_text: "Start Your Subscription Now",
    link: "/contact-lenses/hydro-by-hubble-lenses",
  },
  hydro: {
    sub_title: "HYDRO BY HUBBLE",
    cta_text: "Our most popular daily lens just got better. Get your first order for 80% off, at just $4.99/eye.",
    cta_text_mobile: "Our most popular daily lens just got better.",
    btn_text: "Shop Hydro by Hubble",
    link: "/contact-lenses/hydro-by-hubble-lenses",
    advantages: [
      {
        title: "More Comfortable",
        text: "Hydro by Hubble’s contact lenses have thin, low-friction edges that help your eyelids move comfortably over the lenses."
      },
      {
        title: "More Eco-Friendly",
        text: "Say hello to the world’s slimmest contact lens packaging—all made from recycled and recyclable materials. That means fewer raw materials, less plastic use, and 80% less waste than conventional packaging."
      },
      {
        title: "More Hydrating",
        text: "Made with materials that mimic and bind with your natural tears, locking in moisture and quickly rehydrating your lenses after every blink."
      },
    ]
  },
  hydro2: {
    sub_title: "NATURALLY HYDRATING",
    cta_text: `See things from a new perspective with Hydro <br/>by Hubble daily contact lenses.`,
    cta_text_mobile: "See things from a new perspective with Hydro by Hubble daily contact lenses.",
    btn_text: "Start Your Subscription Now",
    link: "/contact-lenses/hydro-by-hubble-lenses",
    advantages: [
      {
        title: "More Comfortable",
        text: "Hydro by Hubble’s contact lenses have thin, low-friction edges that help your eyelids move comfortably over the lenses."
      },
      {
        title: "More Eco-Friendly",
        text: "Say hello to the world’s slimmest contact lens packaging—all made from recycled and recyclable materials. That means fewer raw materials, less plastic use, and 80% less waste than conventional packaging."
      },
      {
        title: "More Hydrating",
        text: "Made with materials that mimic and bind with your natural tears, locking in moisture and quickly rehydrating your lenses after every blink."
      },
    ]
  },
  skyhy: {
    sub_title: "SAY HELLO TO",
    cta_text: "Meet our NEW Silicone Hydrogel daily contact lenses. Advanced breathability. Ultra comfort.",
    cta_text_mobile: "Meet our NEW Silicone Hydrogel daily contact lenses. Advanced breathability. Ultra comfort.",
    btn_text: "Shop SkyHy by Hubble",
    link: "/contact-lenses/skyhy-by-hubble-lenses",
    advantages: [
      {
        title: "High Oxygen Permeability",
        text: "Silicone hydrogel lenses allow significantly more oxygen to pass through the lens material and help promote eye health."
      },
      {
        title: "Soft Flex Wear",
        text: "Flexible material allows the lenses to mold to the curve of your eye, fitting snugly. As one of the softest lenses available, SkyHy by Hubble provides high-level comfort and a gentle touch."
      },
      {
        title: "Natural Eye Hydration",
        text: "With special surface technology and lens curvature that promotes wettability, SkyHy by Hubble lenses keep moisture locked in for more comfortable, hydrated, all-day wear."
      },
    ]
  },
  lenses: {
    sub_title: "SEE, EASY.",
    title: "Start now for as little as $1.",
    cta_text: "",
    cta_text_mobile: "",
    btn_text: "Shop Contact Lenses",
    link: "/contact-lenses/hubble",
  },
  frames: {
    sub_title: "NOW STARTING AT $49.99",
    title: "Frames for All",
    cta_text: "Choose from our selection of hand-assembled, made-to-order styles, with the option to add blue light filtering to every pair.",
    cta_text_mobile: "Choose from our selection of made-to-order styles.",
    btn_text: "Shop Eyeglasses",
    link: "/glasses",
  },
  astigmatism: {
    sub_title: "NOW 35% OFF FOR YOUR FIRST ORDER",
    title: "Contacts for Astigmatism",
    cta_text: "",
    cta_text_mobile: "",
    btn_text: "Shop Contacts for Astigmatism",
    link: "/contact-lenses/torics",
  },
  newPrices: {
    sub_title: "NATURALLY HYDRATING AND BREATHABLE",
    title: "Designed for Comfort.",
    btn_text: "Shop Now",
    link: "/contact-lenses/hubble",
  },
  skyhyMonthly: {
    sub_title: "SKYHY BY HUBBLE<sup>®</sup>",
    title: "Start for as low <br/>as $2.99/eye*",
    cta_text: "Our Winter Sale is on! Get your first order of SkyHy Daily for $6.99/eye or SkyHy Monthly for $2.99/eye per month.",
    cta_text_mobile: "Our Winter Sale is on! Get your first order of SkyHy Daily for $6.99/eye or SkyHy Monthly for $2.99/eye per month.",
    btn_text: "Shop SkyHy by Hubble",
    link: "/contact-lenses/skyhy",
    disclaimer: "*cost per month.",
  },
})
